import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";

export default function CodeOfConductPage({ data }) {
  const { markdownRemark } = data;
  const { frontmatter } = markdownRemark;

  return (
    <main className="code-of-conduct">
      <SEO
        title={`${frontmatter.seotitle ? frontmatter.seotitle : frontmatter.title} | Inland Northwest Technologists`}
        keywords={[`Inland Northwest Technologists`, `application`, `react`]}
        description={frontmatter.seodescription}
      />
      <header>
        <h1>{frontmatter.title}</h1>
      </header>
      <section className="body" dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
    </main>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        seotitle
        title
      }
    }
  }
`;
